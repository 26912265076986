import { call, put, takeLatest } from "redux-saga/effects";
import request, {requestAxios} from "../../utils/request";
import Swal from 'sweetalert2';

function* getAllWarehouses(payload) {
  try {
    yield put({
      type: "SHOW_LOADING",
    });

    yield put({
      type: "FETCH_WAREHOUSES_REQUESTING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/warehouses/all?page=${payload.value.page}&offset=${payload.value.offset}&search=${payload.value.search}`;

    const headers = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "FETCH_WAREHOUSES_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Load failed",
        message: "Loading Warehouses",
      },
    });
    yield put({
      type: "FETCH_WAREHOUSES_ERROR"
    });
  } finally {
    yield put({
      type: "HIDE_LOADING",
    });
  }
}

function* getWarehouseById(payload) {
  try {
    yield put({
      type: "READ_WAREHOUSE_REQUESTING",
    });
    yield put({
      type: "SHOW_LOADING",
    });
    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/warehouses/${payload.value.id}`;

    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "READ_WAREHOUSE_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "READ_WAREHOUSE_ERROR",
    });
  } finally {
    yield put({
      type: "HIDE_LOADING",
    });
  }
}

function* fetchWarehouseHierarchy(payload) {
  try {
    yield put({
      type: "FETCH_WAREHOUSE_HIERARCHY_REQUESTING",
    });
    yield put({
      type: "SHOW_LOADING",
    });
    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/warehouses/${payload.value.idWarehouse}/hierarchy`;

    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "FETCH_WAREHOUSE_HIERARCHY_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "FETCH_WAREHOUSE_HIERARCHY_ERROR",
    });
  } finally {
    yield put({
      type: "HIDE_LOADING",
    });
  }
}

function* getWarehousesAvailableForHubs(payload) {
  try {
    yield put({
      type: "FETCH_WAREHOUSES_FORHUBS_REQUESTING",
    });
    yield put({
      type: "SHOW_LOADING",
    });
    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/warehouses/availableForHub`;

    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "FETCH_WAREHOUSES_FORHUBS_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "FETCH_WAREHOUSES_FORHUBS_ERROR",
    });
  } finally {
    yield put({
      type: "HIDE_LOADING",
    });
  }
}

function* getShipperWarehouses(payload) {
  try {

    yield put({
      type: "READ_SHIPPER_WAREHOUSES_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/warehouses/shipper/${payload.value.shipper}`;

    const headers = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "READ_SHIPPER_WAREHOUSES_SUCCESS",
      value: response,
    });

  } catch (error) {
    yield put({
      type: "READ_SHIPPER_WAREHOUSES_ERROR",
    });
  } finally {
    yield put({
      type: "HIDE_LOADING",
    });
  }
}

function* createWarehouse(payload) {
  try {
    yield put({
      type: "CREATE_WAREHOUSE_REQUESTING"
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/warehouses`;

    const headers = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload.value),
    };

    const response = yield call(requestAxios, requestURL, headers);

    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "success",
        title: "Successful save",
        message: "Successful save warehouse",
      },
    });

    yield put({
      type: "CREATE_WAREHOUSE_SUCCESS",
      value: response,
    });

  } catch (error) {
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed save",
        message: "Failed save warehouse",
      },
    });
    yield put({
      type: "CREATE_WAREHOUSE_ERROR",
      value: error.response
    });
  } finally {
    yield put({
      type: "HIDE_LOADING",
    });
  }
}

function* updateWarehouse(payload) {
  try {
    yield put({
      type: "UPDATE_WAREHOUSE_REQUESTING"
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/warehouses`;

    const headers = {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload.value),
    };

    const response = yield call(requestAxios, requestURL, headers);

    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "success",
        title: "Successful update",
        message: "Successful update",
      },
    });

    yield put({
      type: "UPDATE_WAREHOUSE_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Update failed",
        message: "Update failed",
      },
    });
    yield put({
      type: "UPDATE_WAREHOUSE_ERROR",
      value: error.response
    });
  } finally {
    yield put({
      type: "HIDE_LOADING",
    });
  }
}

function* createWarehouseHierarchy(payload) {
  try {
    yield put({
      type: "CREATE_WAREHOUSE_HIERARCHY_REQUESTING"
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/warehouses`;

    const headers = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload.value),
    };

    const response = yield call(requestAxios, requestURL, headers);

    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "success",
        title: "Successful save",
        message: "Successful save warehouse",
      },
    });

    yield put({
      type: "CREATE_WAREHOUSE_HIERARCHY_SUCCESS",
      value: response,
    });

  } catch (error) {
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed save",
        message: "Failed save warehouse",
      },
    });
    yield put({
      type: "CREATE_WAREHOUSE_HIERARCHY_ERROR",
      value: error.response
    });
  } finally {
    yield put({
      type: "HIDE_LOADING",
    });
  }
}

function* updateWarehouseHierarchy(payload) {
  try {
    yield put({
      type: "UPDATE_WAREHOUSE_HIERARCHY_REQUESTING"
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/warehouses`;

    const headers = {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload.value),
    };

    const response = yield call(requestAxios, requestURL, headers);

    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "success",
        title: "Successful update",
        message: "Successful update",
      },
    });

    yield put({
      type: "UPDATE_WAREHOUSE_HIERARCHY_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Update failed",
        message: "Update failed",
      },
    });
    yield put({
      type: "UPDATE_WAREHOUSE_HIERARCHY_ERROR",
      value: error.response
    });
  } finally {
    yield put({
      type: "HIDE_LOADING",
    });
  }
}

function* getHistoryMovements(payload) {
  try {

    yield put({
      type: "READ_HISTORY_MOVEMENTS_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    let requestURL = `${process.env.REACT_APP_API_URL}/api/v1/warehouse/wmsInventoryMovements/inventory/${payload.value.idInventory}?page=${payload.value.page}&offset=${payload.value.offset}&search=${payload.value.search}`;
    if (payload.value.from) {
      requestURL += `&startDate=${payload.value.from}`;
    }
    if (payload.value.to) {
      requestURL += requestURL.includes("=")
        ? `&endDate=${payload.value.to}`
        : `endDate=${payload.value.to}`;
    }
    const headers = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "READ_HISTORY_MOVEMENTS_SUCCESS",
      value: response,
    });

  } catch (error) {
    yield put({
      type: "READ_HISTORY_MOVEMENTS_ERROR",
    });
  } finally {
    yield put({
      type: "HIDE_LOADING",
    });
  }
}

function* getProductLocation(payload) {
  try {

    yield put({
      type: "GET_PRODUCTLOCATION_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    let requestURL = `${process.env.REACT_APP_API_URL}/api/v1/warehouse/wmsProductLocations/product/${payload.value.idProduct}/${payload.value.idWarehouse}?page=${payload.value.page ?? ''}&offset=${payload.value.offset ?? ''}`;
    if (payload.value.search) {
      requestURL += `&search=${payload.value.search}`;
    }
    const headers = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "GET_PRODUCTLOCATION_SUCCESS",
      value: response,
    });

  } catch (error) {
    yield put({
      type: "GET_PRODUCTLOCATION_ERROR",
    });
  } finally {
    yield put({
      type: "HIDE_LOADING",
    });
  }
}

function* createProductLocation(payload) {
  try {
    yield put({
      type: "CREATE_PRODUCTLOCATION_REQUESTING"
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/warehouse/wmsProductLocations`;

    const headers = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload.value),
    };

    const response = yield call(requestAxios, requestURL, headers);

    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "success",
        title: "Successful save",
        message: "Successful save Product Location",
      },
    });

    yield put({
      type: "CREATE_PRODUCTLOCATION_SUCCESS",
      value: response,
    });

  } catch (error) {
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed save",
        message: "Failed save Product Location",
      },
    });
    yield put({
      type: "CREATE_PRODUCTLOCATION_ERROR",
      value: error.response
    });
  } finally {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "RESET_PRODUCTLOCATION_FORM"
    });
  }
}

function* getLots(payload) {
  try {

    yield put({
      type: "GET_LOTS_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/warehouse/wmsLots/product/${payload.value.idProduct}?spent=${payload.value.spent ?? ''}&expired=${payload.value.expired ?? ''}&page=${payload.value.page ?? ''}&offset=${payload.value.offset ?? ''}&search=${payload.value.search ?? ''}`;

    const headers = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "GET_LOTS_SUCCESS",
      value: response,
    });

  } catch (error) {
    yield put({
      type: "GET_LOTS_ERROR",
    });
  } finally {
    yield put({
      type: "HIDE_LOADING",
    });
  }
}

function* createLots(payload) {
  try {
    yield put({
      type: "CREATE_LOTS_REQUESTING"
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/warehouse/wmsLots`;

    const headers = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload.value),
    };

    const response = yield call(requestAxios, requestURL, headers);

    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "success",
        title: "Successful save",
        message: "Successful save Product Lot",
      },
    });

    yield put({
      type: "CREATE_LOTS_SUCCESS",
      value: response,
    });

  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed save",
        message: "Failed save Product Lot "+ error.response.message,
        error: error.response
      },
    });
    yield put({
      type: "CREATE_LOTS_ERROR",
      value: error.response
    });
  } finally {
    yield put({
      type: "HIDE_LOADING",
    });
  }
}

function* getWMSOrders(payload) {
  try {

    yield put({
      type: "GET_WMS_ORDERS_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/wms/order?page=${payload.value.page}&search=${payload.value.search}&offset=${payload.value.offset}`;

    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "GET_WMS_ORDERS_SUCCESS",
      value: response,
    });

  } catch (error) {
    yield put({
      type: "GET_WMS_ORDERS_ERROR",
    });
  } finally {
    yield put({
      type: "HIDE_LOADING",
    });
  }
}

function* getWMSOrdersDetails(payload) {
  try {

    yield put({
      type: "READ_WMSORDER_DETAIL_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/wms/order/detail/${payload.value}`;

    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "READ_WMSORDER_DETAIL_SUCCESS",
      value: response,
    });

  } catch (error) {
    yield put({
      type: "READ_WMSORDER_DETAIL_ERROR",
    });
  } finally {
    yield put({
      type: "HIDE_LOADING",
    });
  }
}

function* getWMSOrdersTracking(payload) {
  try {

    yield put({
      type: "READ_WMSORDER_TRACKING_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/wms/order/tracking/${payload.value}`;

    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "READ_WMSORDER_TRACKING_SUCCESS",
      value: response,
    });

  } catch (error) {
    yield put({
      type: "READ_WMSORDER_TRACKING_ERROR",
    });
  } finally {
    yield put({
      type: "HIDE_LOADING",
    });
  }
}

function* generateLabelTracking(payload) {

  try {
    yield put({
      type: "LABEL_TRACKING_REQUESTING",
    });
   
    yield put({
      type: "SHOW_LOADING",
    });
    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/wms/order/label?all=${payload.value.all}&limit=${payload.value.limit}&operator=${payload.value.operator}&branch=${payload.value.branch}&shipper=${payload.value.shipper}&from=${payload.value.from}&to=${payload.value.to}`;

    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      })
    };

    const response = yield call(request, requestURL, headers);    

    if(response != null) {
      const link = document.createElement('a');
      link.href = `data:application/pdf;base64,${response}`;
      link.download = 'ordenesPendientesAlistar.pdf';
  
      link.click();
    }

    yield put({
      type: "LABEL_TRACKING_SUCCESS",
      value: response,
    });
  
    yield put({
      type: "HIDE_LOADING",
    });
   
  } catch (error) {
    yield put({
      type: "LABEL_TRACKING_ERROR",
      value: null,
    });
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed pdf",
        message: "Failed load PDF "+ error,
      },
    });
  }
}

function* enlistmentWMSOrder(payload) {
  try {
    yield put({
      type: "ENLISTMENT_WMSORDER_REQUESTING"
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/wms/order/status`;

    const headers = {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload.value),
    };

    const response = yield call(requestAxios, requestURL, headers);

    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "success",
        title: "Successful save",
        message: "Successful change state",
      },
    });

    yield put({
      type: "ENLISTMENT_WMSORDER_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed save",
        message: "Failed change state",
      },
    });
    yield put({
      type: "ENLISTMENT_WMSORDER_ERROR",
    });
  } finally {
    yield put({
      type: "HIDE_LOADING",
    });
  }
}

function* preparedWMSOrder(payload) {
  try {
    yield put({
      type: "PREPARED_WMSORDER_REQUESTING"
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/wms/order/dispatch`;

    const headers = {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload.value),
    };

    const response = yield call(requestAxios, requestURL, headers);

    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "success",
        title: "Successful save",
        message: "Successful change state",
      },
    });

    yield put({
      type: "PREPARED_WMSORDER_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed save",
        message: "Failed change state",
      },
    });
    yield put({
      type: "PREPARED_WMSORDER_ERROR",
    });
  } finally {
    yield put({
      type: "HIDE_LOADING",
    });
  }
}

function* fetchWarehouseTypes() {
  try {

    yield put({
      type: "FETCH_WAREHOUSE_TYPES_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/warehouses/types`;

    const headers = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "FETCH_WAREHOUSE_TYPES_SUCCESS",
      value: response,
    });

  } catch (error) {
    yield put({
      type: "FETCH_WAREHOUSE_TYPES_ERROR",
    });
  } finally {
    yield put({
      type: "HIDE_LOADING",
    });
  }
}

function* uploadMasivPreparedWMSOrder(payload) {
  try {
    yield put({
      type: "UPLOAD_MASIV_PREPARED_WMSORDER_REQUESTING"
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/wms/order/dispatch-bulk`;

    const formData = new FormData();
    formData.append("file", payload.value.file);
    const headers = {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
      }),
      body: formData,
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "UPLOAD_MASIV_PREPARED_WMSORDER_SUCCESS",
      value: response,
    });
    if (response.results && response.results.some(result => result.status === 'error')) {
      yield put({
        type: "SHOW_ALERT",
        value: {
          type: "warning",
          title: "Proceso finalizado con errores",
          message: response.message || "Se han encontrado errores en algunas órdenes.",
        },
      });
    } else {
      yield put({
        type: "SHOW_ALERT",
        value: {
          type: "success",
          title: "Proceso finalizado con éxito",
          message: response.message || "Todas las órdenes se han procesado correctamente.",
        },
      });
    }
  } catch (error) {
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Error en el proceso",
        message: "Ha ocurrido un error al procesar las órdenes.",
      },
    });
    yield put({
      type: "UPLOAD_MASIV_PREPARED_WMSORDER_ERROR",
      value: error.response || error,
    });
  } finally {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "UPLOAD_MASIV_PREPARED_WMSORDER_DONE",
    });
  }
}

function* downloadWMSOrderToPrepared(payload) {
  try {
    yield put({
      type: "DOWNLOAD_WMSORDER_TO_PREPARED_REQUESTING"
    });

    yield put({
      type: "SHOW_LOADING",
    });

    let requestURL = `${process.env.REACT_APP_API_URL}/api/v1/wms/order/downloadOrdersToDispatch`;
    if (payload.value.idCompany) {
      requestURL += `?idCompany=${payload.value.idCompany}`;
    }
    if (payload.value.idHub) {
      requestURL += `${payload.value.idCompany ? '&' : '?'}idHub=${payload.value.idHub}`;
    }
    const headers = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "DOWNLOAD_WMSORDER_TO_PREPARED_SUCCESS",
      value: response,
    });
console.log(response)
  } catch (error) {
    yield put({
      type: "DOWNLOAD_WMSORDER_TO_PREPARED_ERROR",
    });
  } finally {
    yield put({
      type: "HIDE_LOADING",
    });
  }
}

function* translationWMSOrder(payload) {
  try {
    yield put({
      type: "TRANSLATION_WMSORDER_REQUESTING"
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/warehouse/inventory/relocateStockMovement`;

    const headers = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload.value),
    };

    const response = yield call(requestAxios, requestURL, headers);

      yield put({
        type: "TRANSLATION_WMSORDER_SUCCESS",
        value: response,
      });

  } catch (error) {
    yield put({
      type: "TRANSLATION_WMSORDER_ERROR",
      value: error.response,
    });
  } finally {
    yield put({
      type: "HIDE_LOADING",
    });
  }
}

function* downloadManifest({ payload }) {
  try {
    yield put({ type: "SHOW_LOADING" }); // Start loading

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/warehouse/inventory/downloadManifest/${payload.idManifest}`;

    const headers = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    };

    const response = yield call(fetch, requestURL, headers);
    const blob = yield call([response, 'blob']);

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `Manifest_${payload.idManifest}.pdf`;
    document.body.appendChild(a);
    a.click();
    a.remove();

    yield put({ type: "DOWNLOAD_MANIFEST_SUCCESS" });
  } catch (error) {
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed download",
        message: "Failed to download manifest",
      },
    });
    yield put({ type: "DOWNLOAD_MANIFEST_ERROR" });
  } finally {
    yield put({ type: "HIDE_LOADING" }); 
    yield put({ type: "DOWNLOAD_MANIFEST_DONE" });
  }
}

function* getWMSOrdersPreparationDetail(payload) {
  try {

    yield put({
      type: "READ_WMSORDER_PREPARATION_DETAIL_REQUESTING",
    });
    
    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/wms/order/${payload.value}/products/detail`;

    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "READ_WMSORDER_PREPARATION_DETAIL_SUCCESS",
      value: response,
    });

  } catch (error) {
    yield put({
      type: "READ_WMSORDER_PREPARATION_DETAIL_ERROR",
    });
  } finally {
    yield put({
      type: "HIDE_LOADING",
    });
  }
}

export function* watchWarehouse() {
  yield takeLatest("FETCH_WAREHOUSES_REQUEST", getAllWarehouses);
  yield takeLatest("READ_WAREHOUSE_REQUEST", getWarehouseById);
  yield takeLatest("FETCH_WAREHOUSES_FORHUBS_REQUEST", getWarehousesAvailableForHubs);
  yield takeLatest("CREATE_WAREHOUSE_REQUEST", createWarehouse);
  yield takeLatest("UPDATE_WAREHOUSE_REQUEST", updateWarehouse);
  yield takeLatest("CREATE_WAREHOUSE_HIERARCHY_REQUEST", createWarehouseHierarchy);
  yield takeLatest("UPDATE_WAREHOUSE_HIERARCHY_REQUEST", updateWarehouseHierarchy);
  yield takeLatest("READ_SHIPPER_WAREHOUSES_REQUEST", getShipperWarehouses);
  yield takeLatest("READ_HISTORY_MOVEMENTS_REQUEST", getHistoryMovements);
  yield takeLatest("GET_PRODUCTLOCATION_REQUEST", getProductLocation);
  yield takeLatest("CREATE_PRODUCTLOCATION_REQUEST",createProductLocation);
  yield takeLatest("GET_LOTS_REQUEST",getLots);
  yield takeLatest("CREATE_LOTS_REQUEST",createLots);
  yield takeLatest("GET_WMS_ORDERS_REQUEST", getWMSOrders);
  yield takeLatest("READ_WMSORDER_DETAIL_REQUEST" , getWMSOrdersDetails);
  yield takeLatest("READ_WMSORDER_TRACKING_REQUEST", getWMSOrdersTracking);
  yield takeLatest("LABEL_TRACKING_REQUEST",generateLabelTracking);
  yield takeLatest("ENLISTMENT_WMSORDER_REQUEST", enlistmentWMSOrder);
  yield takeLatest("PREPARED_WMSORDER_REQUEST",preparedWMSOrder);
  yield takeLatest("FETCH_WAREHOUSE_TYPES_REQUEST", fetchWarehouseTypes);
  yield takeLatest("FETCH_WAREHOUSE_HIERARCHY_REQUEST", fetchWarehouseHierarchy);
  yield takeLatest("UPLOAD_MASIV_PREPARED_WMSORDER_REQUEST", uploadMasivPreparedWMSOrder);
  yield takeLatest("DOWNLOAD_WMSORDER_TO_PREPARED_REQUEST", downloadWMSOrderToPrepared);
  yield takeLatest("TRANSLATION_WMSORDER_REQUEST", translationWMSOrder);
  yield takeLatest("DOWNLOAD_MANIFEST_REQUEST", downloadManifest);
  yield takeLatest("READ_WMSORDER_PREPARATION_DETAIL_REQUEST", getWMSOrdersPreparationDetail);
}
