const initialState = {
    requestingFetchWarehouses: false,
    successfulFetchWarehouses: false,
    errorFetchWarehouses: false,
    warehouses: {},
    requestingReadWarehouse: false,
    successfulReadWarehouse: false,
    errorsReadWarehouse: false,
    rowEdited: null,
    requestingCreateWarehouse: false,
    successfulCreateWarehouse: false,
    errorsCreateWarehouse: false,
    requestingUpdateWarehouse: false,
    successfulUpdateWarehouse: false,
    errorsUpdateWarehouse: false,
    upsertResult: null,
    requestingCreateWarehouseHierarchy: false,
    successfulCreateWarehouseHierarchy: false,
    errorsCreateWarehouseHierarchy: false,
    requestingUpdateWarehouseHierarchy: false,
    successfulUpdateWarehouseHierarchy: false,
    errorsUpdateWarehouseHierarchy: false,
    upsertHierarchyResult: null,
    requestingReadShipperWarehouses: false,
    successfulReadShipperWarehouses: true,
    errorReadShipperWarehouses: false,
    shipperWarehouses: {},
    requestingReadHistoryMovements: false,
    successfulReadHistoryMovements: true,
    errorReadHistoryMovements: false,
    historyMovements: {},
    requestingGetProductLocation: false,
    successfulGetProductLocation: false,
    errorGetProductLocation: false,
    productLocations: {},
    requestingCreateProductLocation: false,
    successfulCreateProductLocation: false,
    errorCreateProductLocation: false,
    productLocation: {},
    requestingCreateLots: false,
    successfulCreateLots: false,
    errorCreateLots: false,
    lots: {},
    lot: {},
    requestingGetWMSOrders: false,
    successfulGetWMSOrders: false,
    errorGetWMSOrders: false,
    wmsOrders: {},
    requestingFetchAvailableWarehousesForHub: false,
    successfulFetchAvailableWarehousesForHub: true,
    errorFetchAvailableWarehousesForHub: false,
    warehousesForHubs: {},
    requestingGetWMSTracking: false,
    successfulGetWMSTracking: false,
    errorGetWMSTracking: false,
    ordersEvents: {},
    requestingGetWMSDetails: false,
    successfulGetWMSDetails: true,
    errorGetWMSDetails: false,
    wmsOrderDetails: {},
    requestingGetWMSPrepared: false,
    successfulGetWMSPrepared: false,
    errorGetWMSPrepared: false,
    orderPrepared: {},
    requestingGetWMSEnlistment: false,
    successfulGetWMSEnlistment: false,
    errorGetWMSEnlistment: false,
    orderEnlistment: {},
    requestingGetLabel: false,
    successfulGetLabel: false,
    errorGetLabel: false,
    pdfBase64: null,
    requestingFetchWarehouseTypes: false,
    successfulFetchWarehouseTypes: false,
    errorFetchWarehouseTypes: false,
    warehouseTypes: [],
    requestingFetchWarehousesHierarchy: false,
    successfulFetchWarehousesHierarchy: false,
    errorFetchWarehousesHierarchy: false,
    warehouseHierarchy: [],
    requestingUploadMasivPreparedWMSOrder: false,
    successfulUploadMasivPreparedWMSOrder: false,
    errorUploadMasivPreparedWMSOrder: false,
    masivPreparedWMSOrder: null,
    requestingDownloadWMSOrderToPrepared: false,
    successfulDownloadWMSOrderToPrepared: false,
    errorDownloadWMSOrderToPrepared: false,
    toPreparedWMSOrder: null,
    requestingTranslationWMSOrder: false,
    successfulTranslationWMSOrder: false,
    errorTranslationWMSOrder: false,
    translationWMSOrderResult: null,
    conflictErrorProductId: null, // Initialize this property
    requestingGetWMSPreparationDetail: false,
    successfulGetWMSPreparationDetail: false,
    errorGetWMSPreparationDetail: false,
    wmsOrderPreparationDetail: null,
};

const warehouseReducer = (state = initialState, action) => {
    switch (action.type) {
        case "FETCH_WAREHOUSES_REQUESTING":
            return {
                ...state,
                requestingFetchWarehouses: true,
                successfulFetchWarehouses: false,
                errorFetchWarehouses: false,
            };
        case "FETCH_WAREHOUSES_SUCCESS":
            return {
                ...state,
                errorFetchWarehouses: false,
                requestingFetchWarehouses: false,
                successfulFetchWarehouses: true,
                warehouses: action.value,
            };
        case "FETCH_WAREHOUSES_ERROR":
            return {
                ...state,
                errorFetchWarehouses: true,
                requestingFetchWarehouses: false,
                successfulFetchWarehouses: false,
            };
        case "READ_WAREHOUSE_REQUESTING":
            return {
                ...state,
                requestingReadWarehouse: true,
                successfulReadWarehouse: false,
                errorsReadWarehouse: false,
            };
        case "READ_WAREHOUSE_SUCCESS":
            return {
                ...state,
                errorsReadWarehouse: false,
                requestingReadWarehouse: false,
                successfulReadWarehouse: true,
                rowEdited: action.value,
            };
        case "READ_WAREHOUSE_ERROR":
            return {
                ...state,
                errorsReadWarehouse: true,
                requestingReadWarehouse: false,
                successfulReadWarehouse: false,
            };
        case "CREATE_WAREHOUSE_REQUESTING":
            return {
                ...state,
                requestingCreateWarehouse: true,
                successfulCreateWarehouse: false,
                errorsCreateWarehouse: false,
            };
        case "CREATE_WAREHOUSE_SUCCESS":
            return {
                ...state,
                requestingCreateWarehouse: false,
                successfulCreateWarehouse: true,
                errorsCreateWarehouse: false,
                upsertResult: action.value,
            };
        case "CREATE_WAREHOUSE_ERROR":
            return {
                ...state,
                requestingCreateWarehouse: false,
                successfulCreateWarehouse: false,
                errorsCreateWarehouse: true,
                upsertResult: action.value,
            };
        case "UPDATE_WAREHOUSE_REQUESTING":
            return {
                ...state,
                requestingUpdateWarehouse: true,
                successfulUpdateWarehouse: false,
                errorsUpdateWarehouse: false,
            };
        case "UPDATE_WAREHOUSE_SUCCESS":
            return {
                ...state,
                requestingUpdateWarehouse: false,
                successfulUpdateWarehouse: true,
                errorsUpdateWarehouse: false,
                upsertResult: action.value,
            };
        case "UPDATE_WAREHOUSE_ERROR":
            return {
                ...state,
                requestingCreateWarehouse: false,
                successfulCreateWarehouse: false,
                errorsCreateWarehouse: true,
                upsertResult: action.value,
            };
        case "CREATE_WAREHOUSE_HIERARCHY_REQUESTING":
            return {
                ...state,
                requestingCreateWarehouseHierarchy: true,
                successfulCreateWarehouseHierarchy: false,
                errorsCreateWarehouseHierarchy: false,
            };
        case "CREATE_WAREHOUSE_HIERARCHY_SUCCESS":
            return {
                ...state,
                requestingCreateWarehouseHierarchy: false,
                successfulCreateWarehouseHierarchy: true,
                errorsCreateWarehouseHierarchy: false,
                upsertHierarchyResult: action.value,
            };
        case "CREATE_WAREHOUSE_HIERARCHY_ERROR":
            return {
                ...state,
                requestingCreateWarehouseHierarchy: false,
                successfulCreateWarehouseHierarchy: false,
                errorsCreateWarehouseHierarchy: true,
                upsertHierarchyResult: action.value,
            };
        case "UPDATE_WAREHOUSE_HIERARCHY_REQUESTING":
            return {
                ...state,
                requestingUpdateWarehouseHierarchy: true,
                successfulUpdateWarehouseHierarchy: false,
                errorsUpdateWarehouseHierarchy: false,
            };
        case "UPDATE_WAREHOUSE_HIERARCHY_SUCCESS":
            return {
                ...state,
                requestingUpdateWarehouseHierarchy: false,
                successfulUpdateWarehouseHierarchy: true,
                errorsUpdateWarehouseHierarchy: false,
                upsertHierarchyResult: action.value,
            };
        case "UPDATE_WAREHOUSE_HIERARCHY_ERROR":
            return {
                ...state,
                requestingUpdateWarehouseHierarchy: false,
                successfulUpdateWarehouseHierarchy: false,
                errorsUpdateWarehouseHierarchy: true,
                upsertHierarchyResult: action.value,
            };
        case "RESET_WAREHOUSE_FORM":
            return {
                ...state,
                requestingFetchWarehouses: false,
                successfulFetchWarehouses: false,
                errorFetchWarehouses: false,
                requestingReadWarehouse: false,
                successfulReadWarehouse: false,
                errorsReadWarehouse: false,
                rowEdited: null,
                requestingCreateWarehouse: false,
                successfulCreateWarehouse: false,
                errorsCreateWarehouse: false,
                requestingUpdateWarehouse: false,
                successfulUpdateWarehouse: false,
                errorsUpdateWarehouse: false,
                upsertResult: null,
            };
        case "RESET_WAREHOUSE_HIERARCHY_FORM":
            return {
                ...state,
                requestingCreateWarehouseHierarchy: false,
                successfulCreateWarehouseHierarchy: false,
                errorsCreateWarehouseHierarchy: false,
                requestingUpdateWarehouseHierarchy: false,
                successfulUpdateWarehouseHierarchy: false,
                errorsUpdateWarehouseHierarchy: false,
                upsertHierarchyResult: null,
                requestingReadWarehouse: false,
                successfulReadWarehouse: false,
                errorsReadWarehouse: false,
                rowEdited: null,
            };

        case "READ_SHIPPER_WAREHOUSES_REQUESTING":
            return {
                ...state,
                requestingReadShipperWarehouses: true,
                successfulReadShipperWarehouses: false,
                errorReadShipperWarehouses: false,
            };
        case "READ_SHIPPER_WAREHOUSES_SUCCESS":
            return {
                ...state,
                requestingReadShipperWarehouses: false,
                successfulReadShipperWarehouses: true,
                errorReadShipperWarehouses: false,
                shipperWarehouses: action.value,
            };
        case "READ_SHIPPER_WAREHOUSES_ERROR":
            return {
                ...state,
                requestingReadShipperWarehouses: false,
                successfulReadShipperWarehouses: false,
                errorReadShipperWarehouses: true,
            };
        case "RESET_SHIPPERWAREHOUSES":
            return {
                ...state,
                shipperWarehouses: null,
                warehousesForHubs: null,
            };
        case "FETCH_WAREHOUSES_FORHUBS_REQUESTING":
            return {
                ...state,
                requestingFetchAvailableWarehousesForHub: true,
                successfulFetchAvailableWarehousesForHub: false,
                errorFetchAvailableWarehousesForHub: false,
            };
        case "FETCH_WAREHOUSES_FORHUBS_SUCCESS":
            return {
                ...state,
                requestingFetchAvailableWarehousesForHub: false,
                successfulFetchAvailableWarehousesForHub: true,
                errorFetchAvailableWarehousesForHub: false,
                warehousesForHubs: action.value,
            };
        case "FETCH_WAREHOUSES_FORHUBS_ERROR":
            return {
                ...state,
                requestingFetchAvailableWarehousesForHub: false,
                successfulFetchAvailableWarehousesForHub: false,
                errorFetchAvailableWarehousesForHub: true,
            };
        case "READ_HISTORY_MOVEMENTS_REQUEST":
            return {
                ...state,
                requestingReadHistoryMovements: true,
                successfulReadHistoryMovements: false,
                errorReadHistoryMovements: false,
            };
        case "READ_HISTORY_MOVEMENTS_SUCCESS":
            return {
                ...state,
                requestingReadHistoryMovements: false,
                successfulReadHistoryMovements: true,
                errorReadHistoryMovements: false,
                historyMovements: action.value,
            };
        case "READ_HISTORY_MOVEMENTS_ERROR":
            return {
                ...state,
                requestingReadHistoryMovements: false,
                successfulReadHistoryMovements: false,
                errorReadHistoryMovements: true,
            };
        case "RESET_HISTORY_MOVEMENTS":
            return {
                ...state,
                historyMovements: [],
            };
        case "GET_PRODUCTLOCATION_REQUEST":
            return {
                ...state,
                requestingGetProductLocation: true,
                successfulGetProductLocation: false,
                errorGetProductLocation: false,
            };
        case "GET_PRODUCTLOCATION_SUCCESS":
            return {
                ...state,
                requestingGetProductLocation: false,
                successfulGetProductLocation: true,
                errorGetProductLocation: false,
                productLocations: action.value,
            };
        case "GET_PRODUCTLOCATION_ERROR":
            return {
                ...state,
                requestingGetProductLocation: false,
                successfulGetProductLocation: false,
                errorGetProductLocation: true,
            };
        case "RESET_PRODUCTLOCATION_FORM":
            return {
                ...state,
                productLocations: [],
            };
        case "CREATE_PRODUCTLOCATION_REQUEST":
            return {
                ...state,
                requestingCreateProductLocation: true,
                successfulCreateProductLocation: false,
                errorCreateProductLocation: false,
            };
        case "CREATE_PRODUCTLOCATION_SUCCESS":
            return {
                ...state,
                requestingCreateProductLocation: false,
                successfulCreateProductLocation: true,
                errorCreateProductLocation: false,
                productLocation: action.value,
            };
        case "CREATE_PRODUCTLOCATION_ERROR":
            return {
                ...state,
                requestingCreateProductLocation: false,
                successfulCreateProductLocation: false,
                errorCreateProductLocation: true,
            };
        case "RESET_LOTS_FORM":
            return {
                ...state,
                lots: [],
                lot: [],
                successfulCreateLots: false,
                errorCreateLots: false,
            };
        case "GET_LOTS_REQUEST":
            return {
                ...state,
                requestingGetLots: true,
                successfulGetLots: false,
                errorGetLots: false,
            };
        case "GET_LOTS_SUCCESS":
            return {
                ...state,
                requestingGetLots: false,
                successfulGetLots: true,
                errorGetLots: false,
                lots: action.value,
            };
        case "GET_LOTS_ERROR":
            return {
                ...state,
                requestingGetLots: false,
                successfulGetLots: false,
                errorGetLots: true,
            };
        case "CREATE_LOTS_REQUEST":
            return {
                ...state,
                requestingCreateLots: true,
                successfulCreateLots: false,
                errorCreateLots: false,
            };
        case "CREATE_LOTS_SUCCESS":
            return {
                ...state,
                requestingCreateLots: false,
                successfulCreateLots: true,
                errorCreateLots: false,
                lot: action.value,
            };
        case "CREATE_LOTS_ERROR":
            return {
                ...state,
                requestingCreateLots: false,
                successfulCreateLots: false,
                errorCreateLots: true,
                lot: action.value,
            };
        case "GET_WMS_ORDERS_REQUEST":
            return {
                ...state,
                requestingGetWMSOrders: true,
                successfulGetWMSOrders: false,
                errorGetWMSOrders: false,
            };
        case "GET_WMS_ORDERS_SUCCESS":
            return {
                ...state,
                requestingGetWMSOrders: false,
                successfulGetWMSOrders: true,
                errorGetWMSOrders: false,
                wmsOrders: action.value,
            };
        case "GET_WMS_ORDERS_ERROR":
            return {
                ...state,
                requestingGetWMSOrders: false,
                successfulGetWMSOrders: false,
                errorGetWMSOrders: true,
            };
        case "READ_WMSORDER_DETAIL_REQUEST":
            return {
                ...state,
                requestingGetWMSDetails: true,
                successfulGetWMSDetails: false,
                errorGetWMSDetails: false,
            };
        case "READ_WMSORDER_DETAIL_SUCCESS":
            return {
                ...state,
                requestingGetWMSDetails: false,
                successfulGetWMSDetails: true,
                errorGetWMSDetails: false,
                wmsOrderDetails: action.value,
            };
        case "READ_WMSORDER_DETAIL_ERROR":
            return {
                ...state,
                requestingGetWMSDetails: false,
                successfulGetWMSDetails: false,
                errorGetWMSDetails: true,
            };
        case "READ_WMSORDER_TRACKING_REQUEST":
            return {
                ...state,
                requestingGetWMSTracking: true,
                successfulGetWMSTracking: false,
                errorGetWMSTracking: false,
            };
        case "READ_WMSORDER_TRACKING_SUCCESS":
            return {
                ...state,
                requestingGetWMSTracking: false,
                successfulGetWMSTracking: true,
                errorGetWMSTracking: false,
                ordersEvents: action.value,
            };
        case "READ_WMSORDER_TRACKING_ERROR":
            return {
                ...state,
                requestingGetWMSTracking: false,
                successfulGetWMSTracking: false,
                errorGetWMSTracking: true,
            };
        case "ENLISTMENT_WMSORDER_REQUEST":
            return {
                ...state,
                requestingGetWMSEnlistment: true,
                successfulGetWMSEnlistment: false,
                errorGetWMSEnlistment: false,
            };
        case "ENLISTMENT_WMSORDER_SUCCESS":
            return {
                ...state,
                requestingGetWMSEnlistment: false,
                successfulGetWMSEnlistment: true,
                errorGetWMSEnlistment: false,
                orderEnlistment: action.value,
            };
        case "ENLISTMENT_WMSORDER__ERROR":
            return {
                ...state,
                requestingGetWMSEnlistment: false,
                successfulGetWMSEnlistment: false,
                errorGetWMSEnlistment: true,
            };
        case "PREPARED_WMSORDER_REQUEST":
            return {
                ...state,
                requestingGetWMSPrepared: true,
                successfulGetWMSPrepared: false,
                errorGetWMSPrepared: false,
            };
        case "PREPARED_WMSORDER_SUCCESS":
            return {
                ...state,
                requestingGetWMSPrepared: false,
                successfulGetWMSPrepared: true,
                errorGetWMSPrepared: false,
                orderPrepared: action.value,
            };
        case "PREPARED_WMSORDER__ERROR":
            return {
                ...state,
                requestingGetWMSPrepared: false,
                successfulGetWMSPrepared: false,
                errorGetWMSPrepared: true,
            };
        case "LABEL_TRACKING_REQUEST":
            return {
                ...state,
                requestingGetLabel: true,
                successfulGetLabel: false,
                errorGetLabel: false,
            };
        case "LABEL_TRACKING_SUCCESS":
            return {
                ...state,
                requestingGetLabel: false,
                successfulGetLabel: true,
                errorGetLabel: false,
                pdfBase64: action.value,
            };
        case "LABEL_TRACKING_ERROR":
            return {
                ...state,
                requestingGetLabel: false,
                successfulGetLabel: false,
                errorGetLabel: true,
            };
        case "RESET_LABEL_TRACKING_FORM":
            return {
                ...state,
                pdfBase64: null,
                requestingGetLabel: false,
                successfulGetLabel: false,
                errorGetLabel: false,
            };
        case "FETCH_WAREHOUSE_TYPES_REQUEST":
            return {
                ...state,
                requestingFetchWarehouseTypes: true,
                successfulFetchWarehouseTypes: false,
                errorFetchWarehouseTypes: false,
            };
        case "FETCH_WAREHOUSE_TYPES_SUCCESS":
            return {
                ...state,
                requestingFetchWarehouseTypes: false,
                successfulFetchWarehouseTypes: true,
                errorFetchWarehouseTypes: false,
                warehouseTypes: action.value,
            };
        case "FETCH_WAREHOUSE_TYPES_ERROR":
            return {
                ...state,
                requestingFetchWarehouseTypes: false,
                successfulFetchWarehouseTypes: true,
                errorFetchWarehouseTypes: false,
            };
        case "FETCH_WAREHOUSE_HIERARCHY_REQUESTING":
            return {
                ...state,
                requestingFetchWarehousesHierarchy: true,
                successfulFetchWarehousesHierarchy: false,
                errorFetchWarehousesHierarchy: false,
            };
        case "FETCH_WAREHOUSE_HIERARCHY_SUCCESS":
            return {
                ...state,
                requestingFetchWarehousesHierarchy: false,
                successfulFetchWarehousesHierarchy: true,
                errorFetchWarehousesHierarchy: false,
                warehouseHierarchy: action.value,
            };
        case "FETCH_WAREHOUSE_HIERARCHY_ERROR":
            return {
                ...state,
                requestingFetchWarehousesHierarchy: false,
                successfulFetchWarehousesHierarchy: false,
                errorFetchWarehousesHierarchy: true,
            };

        case "UPLOAD_MASIV_PREPARED_WMSORDER_REQUEST":
            return {
                ...state,
                requestingUploadMasivPreparedWMSOrder: true,
                successfulUploadMasivPreparedWMSOrder: false,
                errorUploadMasivPreparedWMSOrder: false,
            };
        case "UPLOAD_MASIV_PREPARED_WMSORDER_SUCCESS":
            return {
                ...state,
                requestingUploadMasivPreparedWMSOrder: false,
                successfulUploadMasivPreparedWMSOrder: true,
                errorUploadMasivPreparedWMSOrder: false,
                masivPreparedWMSOrder: action.value,
            };
        case "UPLOAD_MASIV_PREPARED_WMSORDER_ERROR":    
            return {
                ...state,
                requestingUploadMasivPreparedWMSOrder: false,
                successfulUploadMasivPreparedWMSOrder: false,
                errorUploadMasivPreparedWMSOrder: true,
            };
        case "UPLOAD_MASIV_PREPARED_WMSORDER_DONE":
            return {
                ...state,
                requestingUploadMasivPreparedWMSOrder: false,
                successfulUploadMasivPreparedWMSOrder: false,
                errorUploadMasivPreparedWMSOrder: false,
                masivPreparedWMSOrder: null,
            };
            case "DOWNLOAD_WMSORDER_TO_PREPARED_REQUEST":
                return {
                    ...state,
                    requestingDownloadWMSOrderToPrepared: true,
                    successfulDownloadWMSOrderToPrepared: false,
                    errorDownloadWMSOrderToPrepared: false,
                };
            case "DOWNLOAD_WMSORDER_TO_PREPARED_SUCCESS":
                return {
                    ...state,
                    requestingDownloadWMSOrderToPrepared: false,
                    successfulDownloadWMSOrderToPrepared: true,
                    errorDownloadWMSOrderToPrepared: false,
                    toPreparedWMSOrder: action.value,
                };
            case "DOWNLOAD_WMSORDER_TO_PREPARED_ERROR":    
                return {
                    ...state,
                    requestingDownloadWMSOrderToPrepared: false,
                    successfulDownloadWMSOrderToPrepared: false,
                    errorDownloadWMSOrderToPrepared: true,
                    toPreparedWMSOrder: null,
                };    
            case "RESET_UPLOAD_MASSIVE_PREPARED_WMSORDER_REQUEST":
                return{
                    ...state,
                    requestingUploadMasivPreparedWMSOrder: false,
                    successfulUploadMasivPreparedWMSOrder: false,
                    errorUploadMasivPreparedWMSOrder: false,
                    masivPreparedWMSOrder: null,
                }; 
            case "RESET_DOWNLOAD_MASSIVE_WMSORDER_TO_PREPARED_REQUEST":
                return{
                    ...state,
                    requestingDownloadWMSOrderToPrepared: false,
                    successfulDownloadWMSOrderToPrepared: false,
                    errorDownloadWMSOrderToPrepared: false,
                    toPreparedWMSOrder: null,
                };
        case "TRANSLATION_WMSORDER_REQUESTING":
            return {
                ...state,
                requestingTranslationWMSOrder: true,
                successfulTranslationWMSOrder: false,
                errorTranslationWMSOrder: false,
            };
        case "TRANSLATION_WMSORDER_SUCCESS":
            return {
                ...state,
                requestingTranslationWMSOrder: false,
                successfulTranslationWMSOrder: true,
                errorTranslationWMSOrder: false,
                translationWMSOrderResultSuccess: action.value,
            };
            case "TRANSLATION_WMSORDER_ERROR":
                return {
                    ...state,
                    requestingTranslationWMSOrder: false,
                    successfulTranslationWMSOrder: false,
                    errorTranslationWMSOrder: true,
                    translationWMSOrderResultError: action.value,
                };

        case "DOWNLOAD_MANIFEST_DONE":
            return {
                ...state,
                requestingTranslationWMSOrder: false,
                successfulTranslationWMSOrder: false,
                errorTranslationWMSOrder: false,
                requestingDownloadManifest: false,
                successfulDownloadManifest: false,
                errorDownloadManifest: false,
            };
        case "FETCH_WMS_RETURNS_SUCCESS":
            return {
                ...state,
                returns: action.value
            };
        case "READ_WMSORDER_PREPARATION_DETAIL_REQUEST":
            return {
                ...state,
                requestingGetWMSPreparationDetail: true,
                successfulGetWMSPreparationDetail: false,
                errorGetWMSPreparationDetail: false,
            };
        case "READ_WMSORDER_PREPARATION_DETAIL_SUCCESS":
            return {
                ...state,
                requestingGetWMSPreparationDetail: false,
                successfulGetWMSPreparationDetail: true,
                errorGetWMSPreparationDetail: false,
                wmsOrderPreparationDetail: action.value,
            };
        case "READ_WMSORDER_PREPARATION_DETAIL_ERROR":
            return {
                ...state,
                requestingGetWMSPreparationDetail: false,
                successfulGetWMSPreparationDetail: false,
                errorGetWMSPreparationDetail: true,
            };
        default:
            return state;
    }
};

export default warehouseReducer;  
